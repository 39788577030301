import { useState, useEffect } from 'react';

export const useProjectConfig = () => {
    const [endDateOfCurrentProject, setEndDateOfCurrentProject] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('/config/values.json')
            .then(response => response.json())
            .then(data => {
                setEndDateOfCurrentProject(data.endDateOfCurrentProject);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error loading project dates configuration:', error);
                setError(error);
                setIsLoading(false);
            });
    }, []);

    return { endDateOfCurrentProject, isLoading, error };
};