import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.css';
import CopyableObfuscatedEmail from "../../email/copyable-email";

const LegalNotice = () => {
    const { t } = useTranslation();

    return (
        <div className="main-container">
            <div className="centered-content-container content-section-spacer">
                <div className="impressum-content">
                    <h1>{t('legal-notice.title')}</h1>

                    <section>
                        <h2>{t('legal-notice.tmgInfo')}</h2>
                        <p className="mb-0"><strong>Younes El Ouarti</strong></p>
                        <p className="mb-0">Kullmannstr. 2</p>
                        <p className="mb-0">60435 Frankfurt am Main</p>
                        <p>{t('commons.germany')}</p>

                        <p className="mb-0"><strong>{t('legal-notice.contact')}:</strong></p>
                        <p className="mb-0">{t('commons.telephone')}: +49 69 27244138</p>
                        <span>{t('commons.email')}: </span><CopyableObfuscatedEmail
                        email="impressum.elouarti@gmail.com"
                        copySuccessText={t('contact.contactDetails.email.emailCopied', 'Email copied!')}
                    />
                    </section>

                    <section>
                        <h2>{t('legal-notice.professionalInfo')}</h2>
                        <p>{t('legal-notice.professionalDescription')}</p>
                        <p><em>({t('legal-notice.professionalActivity')})</em></p>

                        <p className="mb-0"><strong>{t('legal-notice.taxInfo')}:</strong></p>
                        <p>{t('legal-notice.vatInfo')}: DE320530689</p>
                    </section>

                    <section>
                        <h2>{t('legal-notice.contentResponsibility')}</h2>
                        <p className="mb-0">Younes El Ouarti</p>
                        <p className="mb-0">Kullmannstr. 2</p>
                        <p className="mb-0">60435 Frankfurt am Main</p>
                        <p>{t('commons.germany')}</p>
                    </section>

                    <section>
                        <h2>{t('legal-notice.liabilityContent')}</h2>
                        <p>{t('legal-notice.liabilityContentPara1')}</p>
                        <p>{t('legal-notice.liabilityContentPara2')}</p>
                    </section>

                    <section>
                        <h2>{t('legal-notice.liabilityLinks')}</h2>
                        <p>{t('legal-notice.liabilityLinksPara1')}</p>
                        <p>{t('legal-notice.liabilityLinksPara2')}</p>
                    </section>

                    <section>
                        <h2>{t('legal-notice.copyright')}</h2>
                        <p>{t('legal-notice.copyrightPara1')}</p>
                        <p>{t('legal-notice.copyrightPara2')}</p>
                    </section>

                    <p className="update-info">
                        <em>{t('legal-notice.lastUpdated')}</em>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LegalNotice;