import React, { useState, useRef, useEffect } from 'react';
import ObfuscatedEmail from '../obfuscated-email';
import './style.css';

const CopyableObfuscatedEmail = ({ email, className, copySuccessText = 'Email copied!' }) => {
    const [showCopyNotification, setShowCopyNotification] = useState(false);
    const emailRef = useRef(null);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(email)
            .then(() => {
                setShowCopyNotification(true);
                setTimeout(() => {
                    setShowCopyNotification(false);
                }, 2000);
            })
            .catch(err => {
                console.error('Failed to copy email: ', err);
            });
    };

    // Handle selection events
    useEffect(() => {
        const handleSelectionChange = () => {
            const selection = window.getSelection();

            // Check if something is selected
            if (selection.toString().trim().length > 0) {
                // Check if our element contains the selection
                if (emailRef.current && emailRef.current.contains(selection.anchorNode)) {
                    // If user has selected text in our component, provide the full email
                    // We delay this slightly to allow the user to complete their selection
                    setTimeout(() => {
                        // Only copy if the selection is still active
                        if (window.getSelection().toString().trim().length > 0) {
                            copyToClipboard();

                            // clear the selection after copying
                            window.getSelection().removeAllRanges();
                        }
                    }, 200);
                }
            }
        };

        // Add listener for selectionchange event
        document.addEventListener('selectionchange', handleSelectionChange);

        // Cleanup
        return () => {
            document.removeEventListener('selectionchange', handleSelectionChange);
        };
    }, [email]);

    return (
        <span className="copyable-email-container position-relative">
            <span
                ref={emailRef}
                onClick={copyToClipboard}
                className={`${className || ''} copyable-email`}
                role="button"
                tabIndex="0"
                onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        copyToClipboard();
                    }
                }}
            >
                <ObfuscatedEmail email={email} useMailto={false} />
            </span>

            {showCopyNotification && (
                <span className="copy-notification">
                    <i className="bi bi-check-circle me-2"></i>
                    {copySuccessText}
                </span>
            )}
        </span>
    );
};

export default CopyableObfuscatedEmail;