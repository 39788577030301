import React, { useEffect, useRef } from 'react';
import './style.css';

import '@dotlottie/player-component';

const MainTemplateHero = ({
                              imageId,
                              title,
                              tagline,
                              image,
                              lottieAnimation, // Path to the lottie file
                              backgroundColor,
                              textColor,
                              className
                          }) => {
    const lottieRef = useRef(null);

    useEffect(() => {
        // If the lottie ref exists and the component is using lottie (not image)
        if (lottieRef.current && !image && lottieAnimation) {
            // Ensure the element is properly configured after it's rendered
            const dotLottiePlayer = lottieRef.current;
            dotLottiePlayer.load(lottieAnimation);
        }
    }, [lottieAnimation, image]);

    return (
        <div
            className={`main-template-hero ${className || ''}`}
            style={{
                backgroundColor: backgroundColor || '#f8f9fa',
                color: textColor || '#212529'
            }}
        >
            <div className="hero-content-container w-100">
                <div className="hero-content-row">
                    <div className="hero-text-column">
                        <h1 className="hero-title m-0">{title}</h1>
                        <div className="hero-tagline m-0">{tagline}</div>
                    </div>
                    <div id={`${imageId || ''}`} className="hero-image-column">
                        {/* Image takes precedence */}
                        {image ? (
                            <img
                                src={image}
                                alt={title}
                                className="hero-image"
                            />
                        ) : lottieAnimation ? (
                            <div className="hero-lottie-container">
                                <dotlottie-player
                                    ref={lottieRef}
                                    src={lottieAnimation}
                                    background="transparent"
                                    speed="1"
                                    autoplay
                                    className="hero-lottie"
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainTemplateHero;