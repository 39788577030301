import React, { useState, useEffect } from 'react';
import './style.css';

const ObfuscatedEmail = ({ email, useMailto = false, className, children }) => {
    const [emailParts, setEmailParts] = useState({ username: '', domain: '' });
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (email) {
            const parts = email.split('@');
            if (parts.length === 2) {
                setTimeout(() => {
                    setEmailParts({
                        username: parts[0],
                        domain: parts[1]
                    });
                    setIsReady(true);
                }, 300);
            }
        }
    }, [email]);

    // Email content - either children or obfuscated email
    const emailContent = children || (
        isReady ? (
            <span className="email-obfuscated" aria-label={`${email}`}>
        {/* Username displayed normally */}
                <span className="email-part">{emailParts.username}</span>

                {/* @ symbol constructed from CSS */}
                <span className="at-symbol" aria-hidden="true"></span>

                {/* Domain displayed with text direction manipulation */}
                <span className="email-part domain-part">
          {emailParts.domain.split('').reverse().join('')}
        </span>
      </span>
        ) : (
            <span>Loading...</span>
        )
    );

    // If mailto is needed, render as a link
    if (useMailto) {
        return (
            <a
                href={isReady ? `mailto:${emailParts.username}@${emailParts.domain}` : '#'}
                className={className}
                aria-label="Email contact"
            >
                {emailContent}
            </a>
        );
    }

    // Otherwise, render as plain text
    return (
        <span className={`${className || ''} email-text`}>
      {emailContent}
    </span>
    );
};

export default ObfuscatedEmail;