import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.css';
import CopyableObfuscatedEmail from "../../email/copyable-email";

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <div className="main-container">
            <div className="centered-content-container content-section-spacer">
                <div className="privacy-policy-content">
                    <h1>{t('privacyPolicy.title')}</h1>

                    <section>
                        <h2>{t('privacyPolicy.generalInfo')}</h2>
                        <p>{t('privacyPolicy.generalInfoPara1')}</p>
                        <p>{t('privacyPolicy.generalInfoPara2')}</p>
                    </section>

                    <section>
                        <h2>{t('privacyPolicy.responsibleParty')}</h2>
                        <p className="mb-0"><strong>Younes El Ouarti</strong></p>
                        <p className="mb-0">Kullmannstr. 2</p>
                        <p className="mb-0">60435 Frankfurt am Main</p>
                        <p className="mb-0">{t('commons.germany')}</p>
                        <span>{t('commons.email')}: </span><CopyableObfuscatedEmail
                            email="impressum.elouarti@gmail.com"
                            copySuccessText={t('contact.contactDetails.email.emailCopied', 'Email copied!')
                        }
                        />
                    </section>

                    <section>
                        <h2>{t('privacyPolicy.noDataCollection')}</h2>
                        <p>{t('privacyPolicy.noDataCollectionPara1')}</p>
                        <p>{t('privacyPolicy.noDataCollectionPara2')}</p>
                    </section>

                    <section>
                        <h2>{t('privacyPolicy.technicalData')}</h2>
                        <p>{t('privacyPolicy.technicalDataPara1')}</p>
                        <ul>
                            <li>{t('privacyPolicy.technicalDataList.ip')}</li>
                            <li>{t('privacyPolicy.technicalDataList.date')}</li>
                            <li>{t('privacyPolicy.technicalDataList.file')}</li>
                            <li>{t('privacyPolicy.technicalDataList.referrer')}</li>
                            <li>{t('privacyPolicy.technicalDataList.browser')}</li>
                        </ul>
                        <p>{t('privacyPolicy.technicalDataPara2')}</p>
                        <ul>
                            <li>{t('privacyPolicy.technicalDataPurposeList.connection')}</li>
                            <li>{t('privacyPolicy.technicalDataPurposeList.usability')}</li>
                            <li>{t('privacyPolicy.technicalDataPurposeList.security')}</li>
                            <li>{t('privacyPolicy.technicalDataPurposeList.admin')}</li>
                        </ul>
                        <p>{t('privacyPolicy.technicalDataPara3')}</p>
                    </section>

                    <section>
                        <h2>{t('privacyPolicy.googleFonts')}</h2>
                        <p>{t('privacyPolicy.googleFontsPara1')}</p>
                        <p>{t('privacyPolicy.googleFontsPara2')}</p>
                        <p>{t('privacyPolicy.googleFontsPara3')}</p>
                    </section>

                    <section>
                        <h2>{t('privacyPolicy.cookies')}</h2>
                        <p>{t('privacyPolicy.cookiesPara')}</p>
                    </section>

                    <section>
                        <h2>{t('privacyPolicy.localStorage')}</h2>
                        <p>{t('privacyPolicy.localStoragePara1')}</p>
                        <p>{t('privacyPolicy.localStoragePara2')}</p>
                        <p>{t('privacyPolicy.localStoragePara3')}</p>
                        <p>{t('privacyPolicy.localStoragePara4')}</p>
                    </section>

                    <section>
                        <h2>{t('privacyPolicy.noAnalytics')}</h2>
                        <p>{t('privacyPolicy.noAnalyticsPara')}</p>
                    </section>

                    <section>
                        <h2>{t('privacyPolicy.noThirdParty')}</h2>
                        <p>{t('privacyPolicy.noThirdPartyPara')}</p>
                    </section>

                    <section>
                        <h2>{t('privacyPolicy.rights')}</h2>
                        <p>{t('privacyPolicy.rightsPara1')}</p>
                        <ul>
                            <li>{t('privacyPolicy.rightsPoints.point1')}</li>
                            <li>{t('privacyPolicy.rightsPoints.point2')}</li>
                            <li>{t('privacyPolicy.rightsPoints.point3')}</li>
                        </ul>
                        <p>{t('privacyPolicy.rightsPara2')}</p>
                        <p>{t('privacyPolicy.rightsPara3')}</p>
                        <p>{t('privacyPolicy.rightsPara4')}</p>
                    </section>

                    <section>
                        <h2>{t('privacyPolicy.changes')}</h2>
                        <p>{t('privacyPolicy.changesPara1')}</p>
                        <p>{t('privacyPolicy.changesPara2')}</p>
                    </section>

                    <p className="update-info">
                        <em>{t('privacyPolicy.lastUpdated')}</em>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;