import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './style.css';
import {determineAvailabilityState} from "../../util/availabilityUtils";

const AvailabilityBadge = ({ endDateOfCurrentProject }) => {
    const { t, i18n } = useTranslation();

    // Use the utility function
    const badgeState = determineAvailabilityState(endDateOfCurrentProject, i18n);

    // Render appropriate badge based on state
    const renderBadge = () => {
        if (badgeState === 'open') {
            return (
                <div className="availability-badge availability-badge-open">
                    <i className="bi bi-check-circle-fill"></i>
                    {t('commons.availability.openForProjects')}
                </div>
            );
        } else if (badgeState.type === 'startingFrom') {
            return (
                <div className="availability-badge availability-badge-open">
                    <i className="bi bi-check-circle-fill"></i>
                    {t('commons.availability.openForProjectsStarting', {
                        month: badgeState.month,
                        year: badgeState.year
                    })}
                </div>
            );
        } else {
            return (
                <div className="availability-badge availability-badge-consultation">
                    <i className="bi bi-clock-history"></i>
                    {t('commons.availability.consultationOnly', {
                        month: badgeState.month,
                        year: badgeState.year
                    })}
                </div>
            );
        }
    };

    // Additional information to show on hover
    const getTooltipContent = () => {
        if (badgeState === 'open') {
            return t('commons.availability.details.openForProjects');
        } else if (badgeState.type === 'startingFrom') {
            return t('commons.availability.details.openForProjectsStarting', {
                month: badgeState.month,
                year: badgeState.year
            });
        } else {
            return t('commons.availability.details.consultationOnly', {
                month: badgeState.month,
                year: badgeState.year
            });
        }
    };

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{getTooltipContent()}</Tooltip>}
        >
            <div className="availability-badge-container">
                {renderBadge()}
            </div>
        </OverlayTrigger>
    );
};

export default AvailabilityBadge;