// Utility function that maintains the original format
export const determineAvailabilityState = (endDateOfCurrentProject, i18n) => {
    // If no end date is provided
    if (!endDateOfCurrentProject) {
        return 'open';
    }

    const now = new Date();
    const endDate = new Date(endDateOfCurrentProject);

    // Calculate available-from date (10 days after project ends)
    const availableFromDate = new Date(endDate);
    availableFromDate.setDate(endDate.getDate() + 10); // Add 10 days to determine the available-from month

    // Calculate time windows
    const oneMonthBeforeEnd = new Date(endDate);
    oneMonthBeforeEnd.setMonth(endDate.getMonth() - 1);

    const twoMonthsBeforeEnd = new Date(endDate);
    twoMonthsBeforeEnd.setMonth(endDate.getMonth() - 2);

    // Get localized month names based on current language
    const getLocalizedMonth = (date) => {
        // Use the current language from i18n
        return date.toLocaleString(i18n.language, { month: 'long' });
    };

    // Determine badge state
    if (now >= oneMonthBeforeEnd) {
        return 'open';
    } else if (now >= twoMonthsBeforeEnd) {
        return {
            type: 'startingFrom',
            month: getLocalizedMonth(availableFromDate), // Use the available-from date (10 days after project ends)
            year: availableFromDate.getFullYear()
        };
    } else {
        return {
            type: 'consultationOnly',
            month: getLocalizedMonth(endDate),
            year: endDate.getFullYear()
        };
    }
};