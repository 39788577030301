import React, { useState, useEffect } from 'react';
import './style.css';
import image4k from './assets/hero-bg-4k.webp';
import image2k from './assets/hero-bg-2k.webp';
import imageFhd from './assets/hero-bg-fhd.webp';
import imageHd from './assets/hero-bg-hd.webp';
import imageTablet from './assets/hero-bg-tablet.webp';
import imageMobile from './assets/hero-bg-mobile.jpg';
import profileImage from './assets/profile.webp';
import SocialIcons from "../../social-icons";
import AvailabilityBadge from "../../availability-badge";
import { useTranslation } from 'react-i18next';
import {useProjectConfig} from "../../../hooks/useprojectConfig";

const HomeHero = () => {
    const {t} = useTranslation();
    const { endDateOfCurrentProject } = useProjectConfig();

    return (<div className="home-hero-container">
            <picture className="col" id="home-hero-background">
                <source media="(min-width: 3840px)" srcSet={image4k}/>
                <source media="(min-width: 1921px)" srcSet={image2k}/>
                <source media="(min-width: 1301px)" srcSet={imageFhd}/>
                <source media="(min-width: 996px)" srcSet={imageHd}/>
                <source media="(min-width: 811px)" srcSet={imageTablet}/>
                <img src={imageMobile} alt="Clean Desk"/>
            </picture>

            <div className="home-hero-content main-container">
                <div className="row d-flex">
                    {/*profile image*/}
                    <div className="col-auto">
                        <img id="profile-image" src={profileImage} alt="That's me!" className="profile-image"/>
                    </div>

                    {/*info*/}
                    <div className="col d-flex flex-column">
                        <div className="name-badge-container">
                            <h1 id="hhc-name">Younes El Ouarti</h1>
                            <AvailabilityBadge endDateOfCurrentProject={endDateOfCurrentProject} />
                        </div>
                        <p id="hhc-role">Cloud Specialist · IT-Solution Architect</p>
                        <p id="hhc-location">Frankfurt am Main, {t('commons.germany')}</p>
                        <SocialIcons classNamePostfix="hero flex-row"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeHero;